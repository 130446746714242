<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <!-- <div class="note">(*) Nội dung không được bỏ trống</div> -->
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          @updateTitle="updateTitle"
          :model="models"/>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import gql from "graphql-tag";
import Config from '@/config/index'
import DataServices from '@/core/DataServices'
import formEdit from './add'
export default {
  components: {
    formEdit
  },
  data() {
    return {
      validationErrors: [],
      title: "Add user",
      titleTmp: "Add user",
      mode: 'add',
      models: {},
      images: [],
      oldImages: [],
      componentKey: 0,
      time_zone_local : moment.tz.guess(true),
      page_transaction:null
    }
  },
  async mounted () {
    console.log("this.$route.name:",this.$route.query.page_transaction);
    this.mode = this.$route.name === 'editPermission' ? 'edit' : this.mode;
    this.title = this.mode === 'edit' ? 'Edit permission' : this.title;
    //if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    //check quyền
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.ASSIGN_USER);
    if (this.mode == 'edit'){
      if (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }else {
      if (arr_role.indexOf(this.$constants.ROLE.ADD) < 0){
        this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
      }
    }
  },
  methods: {
    updateTitle(data) {
      if (data) {
        this.title = `${data} - ${this.titleTmp}`;
      }
    },
    backToList() {
      this.$router.push({ path: `/admin/list-permission/${this.$route.params.id}/user` });
    },
    convertPhone(numberPhone) {
      var phone = numberPhone
      if (numberPhone[0] === '0') {
        phone = phone.substring(1);
      }
      phone = phone.replace(' ', '')
      phone = '+84' + phone
      return phone
    },
    async saveData(params) {
      // console.log("===params===",params.list_controller[0]);
      var that = this;
      if (params !== undefined && Object.entries(params).length > 0) {
        let i = 0;
        let j = 0;
        return Promise.all(params.listDel.map(async m => {
          j++;
          let objects = {
            uid: {_eq: m?.id},
            group_id: {_eq: Number(this.$route.params.id)}
          }
          await that.$apollo.mutate({
            //mutation: gql(queryUsers),
            mutation: DataServices.deleteData('group_user'),
            variables: {
              where_key: objects
            }
          }).catch(async error => {
            console.log(error);
          });
        }),
        params.users.map(async m => {
          i++;
          if (m?.is_new) {
            let objects = {
              uid: m?.id,
              group_id: this.$route.params.id,
              updated_at: moment.tz(new Date(), this.time_zone_local),
              updated_by: this.$store.getters.user
            }
            await that.$apollo.mutate({
              //mutation: gql(queryUsers),
              mutation: DataServices.insertData('group_user'),
              variables: {
                objects: objects
              }
            }).catch(async error => {
              console.log(error);
            });
          }
        })).then(() => {
          console.log("params.users", params.users.length);
          console.log("params.listDel", params.listDel.length);
          console.log("params.users", i);
          console.log("params.users", j);
          if (i == params.users.length && j == params.listDel.length) {
            this.$commonFuction.is_loading(false);
            that.$toast.add({severity: 'success', summary: 'Success', detail: 'Save data successly', life: 3000});
            this.$router.push({path: `/admin/list-permission/${this.$route.params.id}/user`});
          }
        })
      }
    },
    async saveListCategory(userID,list,type="add") {
      if(type == "add"){
        var list_add = []
        for (let i = 0; i < list.length; i++) {
          const el = list[i];
          list_add.push({uid :userID,category_id : el.id})
        }
        if(list_add.length > 0){
          var query_insert = DataServices.insertData("users_category")
          var variables_insert = {
            objects: list_add
          }
          await this.$apollo.mutate({
            mutation: query_insert,
            variables: variables_insert
          }).then(() => {
          })
        }
      }else if(type == "del"){
        let query = `mutation Mymutation($_in: [bigint!] = "", $_eq: uuid = "") {
          delete_users_category(where: {_and: {category_id: {_in: $_in}, uid: {_eq: $_eq}}}) {
            affected_rows
          }
        }`;
        var list_del = []
        for (let i = 0; i < list.length; i++) {
          const element = list[i];
          list_del.push(element.id)
        }
        if(list_del.length > 0){
          let variables_del = {
            _in : list_del,
            _eq : userID,
          }
          await this.$apollo.mutate({
            mutation: gql(query),
            variables: variables_del
          }).then(() => {
          })
        }
      }
    },
    async uploadImagesOld(userID) {
      var s3client = Config.s3Client('user')
      if (this.images && userID) {
        //xoá avatar
        if (this.oldImages.length > 0) await this.deleteAvatar(s3client);

        this.$store.commit('setLoading', true);
        var objects = null;
        for (let index = 0; index < this.images.length; index++) {
          const img = this.images[index];
          let file = img.source
          if (file !== '' && img.file !== '') {
            let fileName = img.name;
            let random = Math.random().toString().slice(2);
            fileName = `${random}_${fileName}`
            await s3client.uploadFile(file, fileName).then((data) => {
              objects = data.location;
            });
          }
        }
        // console.log(objects);
        if (objects !== null) {
          this.$apollo.mutate({
            mutation: DataServices.updateData('users'),
            variables: {
              'data_update': {
                'avatar': objects
              },
              'where_key': {
                'id': {'_eq': userID}
              }
            }
          }).then(() => {
            // console.log('UPDATE AVATAR THANH CONG')
          }).catch((error) => {
            console.error('ERROR: ', error)
          })
        }
      }
    },
    async deleteAvatar(s3client) {
      for (let i = 0; i < this.oldImages.length; i++) {
        let fileName = this.oldImages[i].name
        await s3client.deleteFile(fileName)
      }
    },
    /*async getData(id) {
      var where = {id: {'_eq': id}};
      var data = await this.$CoreService.getObjectData('group',where);
      console.log("datadatadatadata:",data)
      if (data){
        this.models = data;
      }

    },*/

  },
  // apollo: {
    // categories: {
    //   query() {
    //     return DataServices.getList('category', {"fields" : "id name"});
    //   },
    //   update(data) {
    //     return data[Object.keys(data)];
    //   },
    //   fetchPolicy: "network-only"
    // },
    // users: {
    //   query() {
    //     return DataServices.getList('users', {"fields" : "id fullname phone"});
    //   },
    //   update(data) {
    //     return data[Object.keys(data)];
    //   },
    //   fetchPolicy: "network-only"
    // }
  // }
}
</script>

<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 24px;
    .p-field{
      .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
      .p-col-width{width:500px !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;}
      }
      .box-images{ margin-top: 10px; position: relative;
        .item-img{height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative; border: 0.5px solid #ccc;
          &.p-col-5{width: 18%;;}
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain;}
          .button-clear{width: 18px; height: 18px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }
  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 22px; border-top: 1px solid #B4B4B4; padding: 0; padding-top: 30px;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
</style>